@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700');

button:focus {
  outline: none;
}

.link:focus {
  outline:none;
}

.roboto {
  font-family: 'Roboto', sans-serif;
}

.shadow-30 {
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1), 0 5px 5px 0 rgba(0, 0, 0, 0.1);
}

.brand-powder-blue {
  color: #A0A6EE;
}
.bg-brand-gray {
  background-color: #F6F8FA;
}

.bg-brand-pink {
  background-color: #FF6D8B;
}

.hover-bg-brand-dark-pink:hover {
  background-color: #FF6D8B;
}

.brand-pink {
  color: #FF6D8B;
}
.menu {
  top: 3rem;
  left: -8rem;
  width: 15rem;
}
.menu:after{
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid #ffffff;
  content: " ";
  position: absolute;
  top: -12px;
  left: 75%;
}

.filter-menu-contractor{
  top: 3.5rem;
  right: -.3rem;
  width: 20rem;
}

.filter-menu-contractor:after{
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid #ffffff;
  content: " ";
  position: absolute;
  top: -12px;
  right: 5%;
}

.filter-menu{
  top: 3rem;
  right: 0;
  width: 20rem;
}

.filter-menu:after{
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid #ffffff;
  content: " ";
  position: absolute;
  top: -12px;
  right: 5%;
}

.org-menu{
  top: 2rem;
  left: 1rem;
  width: 20rem;
}

.org-menu:after{
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid #ffffff;
  content: " ";
  position: absolute;
  top: -12px;
  left: 5%;
}

/* React Toggle CSS */

.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4D4D4D;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
  background-color: #19AB27;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #128D15;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4D4D4D;
  border-radius: 50%;
  background-color: #FAFAFA;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #19AB27;
}

.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 3px 2px #0099E0;
  -moz-box-shadow: 0px 0px 3px 2px #0099E0;
  box-shadow: 0px 0px 2px 3px #0099E0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 5px 5px #0099E0;
  -moz-box-shadow: 0px 0px 5px 5px #0099E0;
  box-shadow: 0px 0px 5px 5px #0099E0;
}

/* React Toggle CSS */

/* React Tooltip */
.type-info {
  background-color: #000000 !important;
}

.type-info.place-bottom:after {
  border-bottom-color: #000000 !important;

}


/* Day Picker Input CSS */

.DayPickerInput {
  border-radius: .25rem;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0,0,0,.1);
  padding: .5rem;
  margin-bottom: .5rem;
  background-color: #ffffff;
}

.DayPickerInput input {
  border: none;
}
.DayPickerInput input:focus {
  outline: none;
}

.DayPickerInput-Overlay {
  z-index: 99999
}

/* Day Picker Input CSS */

body {
  background-color: #F6F8FA;
}

.delete-this-pending-invite{
  background: #ba0000;
  padding: 2px 6px;
  color: #fff;
  font-size: 10px;
  border-radius: 6px;
}

.spinner-aligned-right {
  display: grid;
  grid-auto-columns: calc(100% - 50px);
}

.spinner-aligned-right > div {
  grid-area: right;
}

fieldset .otp-area > div {
  justify-content: center;
}

.otp-area input[type="text"] {
  width: 40px !important;
  height: 44px !important;
  margin-right: 5px;
  margin-left: 5px;
  border: 1px solid #a0a0a0;
  border-radius: 4px;
}

.otp-area input[type="text"]:focus-visible {
  outline: #FF6D8B auto 1px;
}

.wage-viewport {
  overflow: hidden;
  height: 26px;
}

.wage-container {
  transition: .2s ease;
}

.assignment-table-row:hover .wage-container {
  transform: translate3d(0, -20px, 0);
}

.edit-button-container {
  transition: .2s ease;
  transform: translate3d(0, 28px, 0);
}

.assignment-table-row:hover .edit-button-container {
  transform: translate3d(0, 0, 0);
}

.invoice-history-modal table {
  box-shadow: 0 1px 2px rgba(220, 220, 229, .5) !important;
}

.invoice-history-modal .voided-badge{
  letter-spacing: 0.2em;
}

.invoice-history-modal .paid-in-full-badge {
  letter-spacing: 0.2em;
}

.invoice-history-modal .stripes {
  background-image: linear-gradient(315deg, #e8002b 23.81%, #ffffff 23.81%, #ffffff 50%, #e8002b 50%, #e8002b 73.81%, #ffffff 73.81%, #ffffff 100%);
  background-size: 25px 25px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0.08;
  z-index: -1;
}

.wage-change-input {
  border-radius: 6px;
  border: 1px solid #dcdce5;
  box-shadow: 0 1px 2px rgba(220, 220, 229, .5);
  padding-left: 18px;
  height: 36px;
  position: relative;
}

.wage-change-input .sign {
  border: 0;
  padding-top: 10px;
  height: 36px;
  position: absolute;
  width: 28px;
  text-align: center;
  left: 0;
  font-size: 15px;
  color: #000000;
  font-weight: 700;
}

.wage-change-input input {
  border: 0;
  height: 36px;
  font-weight: 700;
}

.update-wage-button {
  position: absolute;
  top: 0;
  right: -90px;
}

.update-wage-button button {
  height: 37px;
  width: 76px;
}

.update-wage-button > .button-reset > .center {
  position: absolute;
  top: 9px;
  left: 28px;
}

.indicator {
  height: 2px;
  background-color: blue; /* Use your desired color */
  position: absolute;
  bottom: 0;
  transition: left 0.3s ease, width 0.3s ease; /* Smooth transition for moving and resizing */
}

